import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
const browserSupported = browser.satisfies({
    chrome: '>=67.0',
    firefox: '>=60.0',
    safari: '>=11',
    edge: '>=17',
});

if (!browserSupported) {
    document.body.classList.add('browser-unsupported');
}

// Hide browser-warning class when dismiss button is clicked
document.addEventListener('DOMContentLoaded', () => {
    const dismissButton = document.querySelector('#dismiss-button');
    if (dismissButton) {
        dismissButton.addEventListener('click', () => {
            const warningContainer = document.querySelector('.browser-warning');
            if (warningContainer) {
                warningContainer.style.display = 'none';
            }
        });
    }
});
